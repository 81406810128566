.App {
  background-color: #B0BEC5;
  width: 100vw;
  height: 100vh;
  margin: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container{
  background-color: white;
  width: 350px;
  padding: 20px;
}

